import React from 'react';
import QustIs from '../../components/mainPage/qustIs/QustIs';
import { MainPageStyles } from './MainPageStyles';
import Slogan from '../../components/mainPage/slogan/Slogan';
import SuitesFor from '../../components/mainPage/suitesFor/SuitesFor';
import Cases from '../../components/mainPage/cases/Cases';
import Banner from '../../components/mainPage/banner/Banner';
import HowToUse from '../../components/mainPage/howToUse/HowToUse';

function Main() {
  return (
    <MainPageStyles>
      <Banner />
      <QustIs />
      <HowToUse />
      <SuitesFor />
      <Cases />
      <Slogan />
    </MainPageStyles>
  );
}

export default Main;
