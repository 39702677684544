import React, { useState } from 'react';
import { BannerStyles } from './BannerStyles';
import showTextByLetter from '../../../utils/ShowTextByLetter/ShowTextByLetter';

import avatar1 from '../../../assets/images/banner/Avatar1.png';
import avatar2 from '../../../assets/images/banner/Avatar2.png';
import avatar3 from '../../../assets/images/banner/Avatar3.png';
import { SwiperSlide } from 'swiper/react';
import SwiperCenteredSlidesPerViewAuto
  from '../../slider/swiperCenteterSlidesPerViewAuto/SwiperCenteredSlidesPerViewAuto';
import { Link } from 'react-router-dom';

const titleTexts = [
  'Get new',
  'Communicate with your',
  'Collect info about your',
  'Sell to',
  'Return your',
  'Automate process with',
];

const starIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M17.7359 6.89552L12.7769 6.17481L10.5601 1.68067C10.4996 1.55762 10.4 1.45802 10.2769 1.39747C9.96833 1.24512 9.59333 1.37208 9.43903 1.68067L7.22223 6.17481L2.26325 6.89552C2.12653 6.91505 2.00153 6.9795 1.90583 7.07716C1.79013 7.19607 1.72637 7.35606 1.72857 7.52196C1.73077 7.68787 1.79874 7.84611 1.91755 7.96192L5.50544 11.46L4.65778 16.3994C4.6379 16.5143 4.65062 16.6325 4.69449 16.7405C4.73835 16.8486 4.81161 16.9422 4.90596 17.0107C5.00031 17.0792 5.11198 17.1199 5.22829 17.1282C5.3446 17.1365 5.46091 17.1121 5.56403 17.0576L9.99958 14.7256L14.4351 17.0576C14.5562 17.1221 14.6968 17.1436 14.8316 17.1201C15.1715 17.0615 15.4 16.7393 15.3414 16.3994L14.4937 11.46L18.0816 7.96192C18.1793 7.86622 18.2437 7.74122 18.2633 7.6045C18.316 7.2627 18.0777 6.9463 17.7359 6.89552Z" fill="#FEBF1C" />
  </svg>
);

const starIcons = (new Array(5).fill(0) as any[]).map((_, index) => ({ icon: starIcon, key: index }));

const reviews = [
  {
    name: 'Mike',
    position: 'CEO English school',
    reviewText: 'It\'s simple, free and effective. It was really good idea to start using QuST.',
    image: avatar1,
  },
  {
    name: 'Anastasia',
    position: 'Marketing Manager',
    reviewText: 'I use QuST for all my projects and I sincerely recommend it to others.',
    image: avatar2,
  },
  {
    name: 'Rustam',
    position: 'Strategy Consultant',
    reviewText: 'It literally took me like 7 minutes to start getting new leads, that\'s amazing!',
    image: avatar3,
  },
];

function Banner() {
  const [activeTitleTextItemIndex, setActiveTitleTextItemIndex] = useState<number>(0);

  return (
    <BannerStyles className="blockPaddingBottom">
      <div className="contentWrapper">
        <div className="background" />
        <div className="container">
          <div className="titleWrapper">
            <h1>
              {showTextByLetter({
                text: titleTexts[activeTitleTextItemIndex],
                callback: () => setActiveTitleTextItemIndex(
                  (prev) => (prev + 1 < titleTexts.length ? prev + 1 : 0),
                ),
                delay: 100,
                deleteTextByLetterBeforeCallback: true,
              })}
              {' '}
              clients with QuST
            </h1>

            <h1>
              {showTextByLetter({
                text: titleTexts[activeTitleTextItemIndex],
                callback: () => setActiveTitleTextItemIndex(
                  (prev) => (prev + 1 < titleTexts.length ? prev + 1 : 0),
                ),
                delay: 100,
                deleteTextByLetterBeforeCallback: true,
              })}
              <br />
              clients with QuST
            </h1>

            <span className="textBase18">One-stop platform to boost your digital marketing</span>
          </div>

          <ul className="reviewsList contentDesktop">
            {reviews.map((review) => (
              <li className="review" key={review.reviewText}>
                <div className="stars">
                  {starIcons.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>{item.icon}</div>
                  ))}
                </div>

                <p className="textSemiBold16 reviewText">{review.reviewText}</p>

                <div className="userContainer">
                  <img src={review.image} alt={`${review.name} ${review.position}`} />

                  <div className="nameAndPositionContainer">
                    <span className="textSemiBold16 name">
                      {review.name}
                    </span>

                    <span className="textBase14 position">{review.position}</span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="reviewsList contentMobile">
          <SwiperCenteredSlidesPerViewAuto
            disabledNext
            disabledPrev
            centeredSlides={false}
            loop={false}
            offsetLeft={16}
            offsetRight={16}
          >
            {reviews.map((review) => (
              <SwiperSlide key={review.reviewText}>
                <div className="review">
                  <div className="stars">
                    {starIcons.map((item, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={index}>{item.icon}</div>
                    ))}
                  </div>

                  <p className="textSemiBold16 reviewText">{review.reviewText}</p>

                  <div className="userContainer">
                    <img src={review.image} alt={`${review.name} ${review.position}`} />

                    <div className="nameAndPositionContainer">
                      <span className="textSemiBold16 name">
                        {review.name}
                      </span>

                      <span className="textBase14 position">{review.position}</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </SwiperCenteredSlidesPerViewAuto>

        </div>

        <div className="container">
          <a href={`${process.env.REACT_APP_QUST_CRM_URL}/sign-up`} className="button" title="Sign up">Start getting value</a>
        </div>
      </div>
    </BannerStyles>
  );
}

export default Banner;
