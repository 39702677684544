import React from 'react';
import Logo from '../../../assets/Logo';
import { HeaderStyles } from './HeaderStyles';
import { Link, useLocation } from 'react-router-dom';

function Header() {
  const location = useLocation();

  return (
    <HeaderStyles>
      <div className="content container">
        <Link to="/">
          <Logo />
        </Link>

        {!location.pathname.includes('thank-you') && (
          <div className="buttonsWrapper">
            <a href={`${process.env.REACT_APP_QUST_CRM_URL}`} className="button" title="Sign in">
              Sign in
            </a>

            <a href={`${process.env.REACT_APP_QUST_CRM_URL}/sign-up`} className="button" title="Sign up">
              Sign up
            </a>
          </div>
        )}
      </div>
    </HeaderStyles>
  );
}

export default Header;
